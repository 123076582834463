import * as Sentry from '@sentry/react';
import React from 'react';

import '@/common/scripts/sentry';
import { Page } from '@/core/components';
import { getIsErrorReportingEnabled } from '@/env/client/config';
import { Card, ErrorScreen } from '@/ui/layout';

export class ErrorBoundary<
  T extends { children: React.ReactNode }
> extends React.Component<T, { hasError: boolean }> {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
    if (getIsErrorReportingEnabled()) {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });

        Sentry.captureException(error);
      });
      console.log('[ops]', 'Error captured');
    }

    if (super.componentDidCatch) {
      super.componentDidCatch(error, errorInfo);
    }
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Page>
          <ErrorScreen>
            <Card>Something has gone wrong!</Card>
          </ErrorScreen>
        </Page>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}
export default ErrorBoundary;
